<template>
  <b-form class="mb-1">
    <validation-observer ref="ProfileForm" #default="{ invalid }">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-0 mr-1">{{ title }}</h3>
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            class="text-dark bg-white font-weight-bolder mr-2"
            @click="$emit('closeForm')"
          >
            Cancelar
          </b-button>
          <b-button variant="primary" :disabled="invalid" @click="createInvite">
            <span class="text-dark font-weight-bolder"> Criar convite </span>
          </b-button>
        </div>
      </div>
      <b-form-group label-class="font-weight-bold" label-cols="12" class="mb-0">
        <b-row>
          <b-col cols="4">
            <b-form-group label="Nome" label-for="nome">
              <validation-provider #default="{ errors }" name="nome" vid="nome" rules="required">
                <b-form-input
                  id="nome"
                  v-model="invite.nome"
                  :state="errors.length > 0 ? false : null"
                  name="nome"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Celular" label-for="telefone">
              <validation-provider
                #default="{ errors }"
                name="telefone"
                vid="telefone"
                rules="min:15"
              >
                <b-form-input
                  id="telefone"
                  v-model="invite.telefone"
                  v-mask="['(##) #####-####']"
                  :state="errors.length > 0 ? false : null"
                  name="telefone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Email" label-for="apto_para_operar">
              <validation-provider
                #default="{ errors }"
                name="email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="invite.email"
                  :state="errors.length > 0 ? false : null"
                  name="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <div class="">
              <div class="p-1 bg-gray border-gray">
                <span> Inclua abaixo os códigos IF que este convidado terá acesso. </span>
                <div class="d-flex mt-1 align-items-start">
                  <b-form-input v-model="codigo_if" placeholder="Digite o código IF" />
                  <b-button
                    variant="primary"
                    :disabled="codigo_if.length < 2"
                    class="text-nowrap ml-2 d-flex align-items-center"
                    @click="addCodigoIF"
                  >
                    Incluir código IF
                    <feather-icon icon="PlusIcon" class="ml-50" size="18" />
                  </b-button>
                </div>
              </div>
              <div class="cnpjListBody">
                <vue-scrollbar ref="Scrollbar" :style="scrollbar">
                  <table class="table-container">
                    <tr v-for="(code, index) in if_codes" :key="index">
                      <td class="w-100">
                        {{ code }}
                      </td>
                      <td>
                        <b-button
                          variant="flat-warning"
                          size="sm"
                          @click="deleteCode(code)"
                          class="text-nowrap"
                        >
                          Excluir <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </td>
                    </tr>

                    <div
                      v-if="!if_codes.length"
                      class="d-flex justify-content-between align-items-center p-1"
                    >
                      <span> Nenhuma empresa cadastrada </span>
                    </div>
                  </table>
                </vue-scrollbar>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form-group>
    </validation-observer>
  </b-form>
</template>

<script>
import { BRow, BCol, BForm, BButton, BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mask } from 'vue-the-mask'
import VueScrollbar from 'vue2-scrollbar'
import 'vue2-scrollbar/dist/style/vue2-scrollbar.css'

export default {
  name: 'InviteForm',
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    VueScrollbar,
  },
  props: {
    title: {
      type: String,
      default: 'Criando novo convite',
    },
  },
  directives: { mask },
  data() {
    return {
      codigo_if: '',
      if_codes: [],
      invite: {
        nome: '',
        email: '',
        telefone: '',
      },
      scrollbar: {
        maxHeight: '250px',
        width: '100%',
      },
    }
  },
  methods: {
    async createInvite() {
      this.$swal.fire({
        title: 'Processando',
        text: 'Aguarde enquanto o convite é criado',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onOpen: () => {
          this.$swal.showLoading()
        },
      })

      try {
        const invite = {
          ...this.invite,
          ifb3_autorizados: this.if_codes,
        }
        await this.$store.dispatch('admin/addInvestorInvite', invite)
        this.$swal
          .fire({
            title: 'Sucesso',
            text: 'Convite criado com sucesso',
            icon: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          })
          .then((result) => {
            this.$emit('closeForm')
          })
      } catch (error) {
        console.log(error)
      }
    },
    addCodigoIF() {
      this.if_codes.push(this.codigo_if)
      this.codigo_if = ''
    },
    deleteCode(code) {
      this.if_codes = this.if_codes.filter((item) => item !== code)
    },
  },
}
</script>

<style lang="scss">
.border-gray {
  border: 1px solid #dae1e8;
}

.bg-gray {
  background-color: #eff4f6;
}

.table-container {
  width: 100%;
  border-collapse: collapse;
}

.table-container td {
  border: 1px solid #dae1e8;
  border-top: none;
  padding: 8px 12px;
  text-align: left;
}

.table-container td.cnpj {
  width: 15%;
  white-space: nowrap;
}

.table-container td.button {
  width: 12%;
  white-space: nowrap;
}
</style>
