<template>
  <b-card>
    <invite-form @closeForm="closeInviteForm" title="Informações do usuário" />
  </b-card>
</template>

<script>
import { BCard, BButton, BInputGroup, BInputGroupAppend, BFormInput } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Datatable from '@/views/common/crud/components/Datatable.vue'
import InviteForm from '@/views/admin/investor/components/InviteForm.vue'
import InviteCard from '@/views/admin/investor/components/InviteCard.vue'

export default {
  name: 'AdminInvestorIndexPage',
  components: {
    vSelect,
    BCard,
    BButton,
    Datatable,
    BFormInput,
    InviteForm,
    InviteCard,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      invites: [],
      search: '',
      perPage: 10,
      perPageOptions: [10, 25, 50],
      showInviteForm: false,
    }
  },
  async mounted() {
    await this.fetchInvites()
    if (this.invites.length === 0) this.showInviteForm = true
  },
  methods: {
    async fetchInvites() {
      const { data } = await this.$store.dispatch('admin/fetchInvestorInvites')
      this.invites = data.data
    },
    closeInviteForm() {
      this.$router.push({ name: 'admin.investor.invites' })
    },
  },
}
</script>

<style lang="scss">
.mw-173 {
  max-width: 173px;
}
</style>
